.app {
  /* background-color: rebeccapurple; */
  overflow-x: hidden;
  height: 100vh;
}

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

/* position dots in carousel */
.flickity-page-dots {
  bottom: 40px !important;
  z-index: 999;
}
/* white circles */
.flickity-page-dots .dot {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: transparent;
  border: 2px solid black;
}
/* fill-in selected dot */
.flickity-page-dots .dot.is-selected {
  background: white;
}
