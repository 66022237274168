.screen {
  height: calc(100vh - 50px);
  min-height: calc(100vh - 50px);
  /* border: 1px solid black; */
}

.screen:last-of-type {
  height: calc(100vh - 50px);
  min-height: calc(100vh - 50px);
}
