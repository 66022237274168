.section {
  margin-left: 5px;
  margin-right: 5px;
}

li.fadein {
  opacity: 1;
  transition: opacity 0.3s, transform 0.3s;
  transform: translateX(0em);
}

li.fadeout {
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  /* transition-delay: transform 0.5s; */
  transform: translateX(2em);
  will-change: transform, opacity;
}
