body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  margin: 0;
  height: auto;
}
html {
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in Internet Explorer 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;
}

.flickity-slider {
  height: calc(100vh - 50px) !important;
  min-height: calc(100vh - 50px) !important;
}

.flickity-button {
  background: none !important;
}

.flickity-prev-next-button {
  width: 100px !important;
  height: 100vh !important;
  z-index: 999 !important;
  transition: color 0.1s !important;
}

.flickity-prev-next-button.next {
  padding-right: 0;
  /* right: 0 !important; */
}

.flickity-prev-next-button.previous {
  padding-left: 0;
}

.flickity-prev-next-button:hover {
  width: 100px;
  background: none !important;
  color: #ff3333;
  mix-blend-mode: none !important;
}

.flickity-button:disabled {
  display: none;
}

.hoverbrightness {
  transition: filter 0.3s;
}
.hoverbrightness:hover {
  filter: brightness(85%);
}

.screen:not(.is-selected) {
  display: "none";
}

.rotatingrect {
  /* width: 100%;
  height: 100%; */
  position: absolute;
  transform-style: preserve-3d;
}
